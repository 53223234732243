.toast {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: var(--color-accent-glass);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 0px 25px var(--color-green);
    animation: fadeInOut 3s forwards;
    z-index: 1000;
    border: 1px solid var(--color-green);
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    10% {
        opacity: 1;
        transform: translateY(0);
    }

    90% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(20px);
    }
}
:root {
  /* Kleuren */
  --color-primary-bg: #1E0633;
  /* Donkerpaars */
  --color-accent-bg: #2A0845;
  --color-accent-glass: rgba(42, 8, 69, 0.507);
  /* Zacht paars */
  --color-glass: rgba(255, 255, 255, 0.1);
  /* Glasachtig transparant */
  --color-blue: #3A86FF;
  /* Elektrisch blauw */
  --color-pink: #FF006E;
  /* Neonroze */
  --color-green: #00F5D4;
  /* Mintgroen */
  --color-neutral-dark: #11001C;
  /* Neutraal zwartpaars */
  --color-light-text: #E0BBE4;
  /* Licht lavendel */

  /* Basis font-maten */
  --font-size-base: 1px;
  --font-size-h1-mobile: 2.5rem;
  --font-size-h1-tablet: 3.5rem;
  --font-size-h1-desktop: 4.5rem;
  --font-size-h2-mobile: 2rem;
  --font-size-h2-tablet: 2.75rem;
  --font-size-h2-desktop: 3.5rem;
  --font-size-h3-mobile: 1.75rem;
  --font-size-h3-tablet: 2.25rem;
  --font-size-h3-desktop: 3rem;
  --font-size-h4-mobile: 1.5rem;
  --font-size-h4-tablet: 2rem;
  --font-size-h4-desktop: 2.5rem;
  --font-size-h5-mobile: 1.25rem;
  --font-size-h5-tablet: 1.75rem;
  --font-size-h5-desktop: 2rem;
  --font-size-h6-mobile: 1rem;
  --font-size-h6-tablet: 1.5rem;
  --font-size-h6-desktop: 1.75rem;
  --font-size-p-mobile: 1rem;
  --font-size-p-tablet: 1rem;
  --font-size-p-desktop: 1rem;
}

.p-1 {
  padding: 0.25rem;
  /* 4px */
}

.p-2 {
  padding: 0.5rem;
  /* 8px */
}

.p-4 {
  padding: 1rem;
  /* 16px */
}

.p-8 {
  padding: 2rem;
  /* 32px */
}

/* Padding voor individuele zijden */
.pt-4 {
  padding-top: 1rem;
  /* Top */
}

.pb-4 {
  padding-bottom: 1rem;
  /* Bottom */
}

.pl-4 {
  padding-left: 1rem;
  /* Left */
}

.pr-4 {
  padding-right: 1rem;
  /* Right */
}

/* Horizontaal en verticaal */
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 0 1rem 0;
  line-height: 1.4;
}

/* Responsive headers */
h1 {
  font-size: var(--font-size-h1-mobile);
  color: var(--color-primary);
}

h2 {
  font-size: var(--font-size-h2-mobile);
  color: var(--color-secondary);
}

h3 {
  font-size: var(--font-size-h3-mobile);
  color: var(--color-accent);
}

h4 {
  font-size: var(--font-size-h4-mobile);
  color: var(--color-extra);
}

h5 {
  font-size: var(--font-size-h5-mobile);
  color: var(--color-contrast);
}

h6 {
  font-size: var(--font-size-h6-mobile);
  color: var(--color-neutral);
}

/* Paragrafen */
p {
  font-size: var(--font-size-p-mobile);
  color: var(--color-contrast);
  margin: 0;
}

/* Responsive aanpassingen */
@media (min-width: 768px) {
  h1 {
    font-size: var(--font-size-h1-tablet);
  }

  h2 {
    font-size: var(--font-size-h2-tablet);
  }

  h3 {
    font-size: var(--font-size-h3-tablet);
  }

  h4 {
    font-size: var(--font-size-h4-tablet);
  }

  h5 {
    font-size: var(--font-size-h5-tablet);
  }

  h6 {
    font-size: var(--font-size-h6-tablet);
  }

  p {
    font-size: var(--font-size-p-tablet);
  }
}

@media (min-width: 1024px) {
  h1 {
    font-size: var(--font-size-h1-desktop);
  }

  h2 {
    font-size: var(--font-size-h2-desktop);
  }

  h3 {
    font-size: var(--font-size-h3-desktop);
  }

  h4 {
    font-size: var(--font-size-h4-desktop);
  }

  h5 {
    font-size: var(--font-size-h5-desktop);
  }

  h6 {
    font-size: var(--font-size-h6-desktop);
  }

  p {
    font-size: var(--font-size-p-desktop);
  }
}

body {
  background-color: var(--color-primary-bg);
  color: #fff;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

button {
  background-color: var(--color-primary);
  color: var(--color-extra);
  border: 2px solid var(--color-secondary);
}

button:hover {
  background-color: var(--color-secondary);
  color: var(--color-neutral);
}

a {
  color: var(--color-green);
  text-decoration: none;
  transition: .35s;
}

a:hover {
  color: var(--color-pink);
  text-shadow: 0px 0px 10px var(--color-pink);
}

.btn-primary {
  display: block;
  background: var(--color-pink);
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.btn-primary:hover {
  background: var(--color-pink);
  color: #fff;
}

.glassmorphism {
  background: rgba(255, 255, 255, 0.1);
  /* Transparantie */
  backdrop-filter: blur(10px);
  /* Wazig effect */
  border: 1px solid rgba(255, 255, 255, 0.2);
  /* Afronding */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  /* Subtiele schaduw */
}

section {
  min-height: 66vh;
  padding-block: 100px;
  background: url('./assets/bg-hero.png') no-repeat top center;
  background-size: cover;
  background-attachment: fixed;
  /* Zorgt ervoor dat de achtergrond niet scrolt */
}

.divider {
  height: .5px;
  display: block;
  width: 100%;
  margin-block: 10px;
}

.divider.green {
  background-color: #00F5D4;
  box-shadow: 0px 0px 4px var(--color-green);
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="phone"],
input[type="number"] {
  display: block;
  width: 100%;
  background: var(--color-glass);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
}

.container {
  max-width: 1600px;
  /* Max breedte voor grote schermen */
  margin-inline: auto;
  /* Centreer horizontaal */
  padding-inline: 20px;
  /* Voeg padding aan de zijkanten toe */
}

.box {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--color-accent-glass)
}

/* Brede schermen (laptops, desktops) */
@media (max-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

/* Tablets en kleine laptops */
@media (max-width: 992px) {
  .container {
    max-width: 960px;
  }
}

/* Kleine tablets en grote telefoons */
@media (max-width: 768px) {
  .container {
    max-width: 720px;
  }
}

/* Smartphones */
@media (max-width: 576px) {
  .container {
    max-width: 100%;
    /* Laat de container de volledige breedte innemen */
    padding-inline: 15px;
    /* Zorg voor een beetje ruimte aan de zijkanten */
  }
}
.product-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;

}

.product-card {
    flex: 0 1 calc(33.33% - 20px);
    /* Zorgt voor 3 kaarten per rij, rekening houdend met de gap */
    box-sizing: border-box;
    /* Inclusief padding en border in de breedte */
    background-color: var(--color-accent-glass);
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: .35s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.product-card:hover {
    transform: translateY(-2%);
}

.visibility-hidden {
    visibility: hidden;
    height: 0;
    opacity: 0;
}

.product-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.product-content {
    padding: 15px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.product-badges {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.badge {
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
}

.badge.safe {
    background-color: var(--color-pink);
}

.badge.high-sales {
    background-color: var(--color-pink);
}

.product-title {
    font-size: 18px;
    margin: 10px 0;
    color: var(--color-green);
}

.product-actions {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.btn {
    padding: 15px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    color: white;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
}

.btn.add-to-cart {
    background-color: var(--color-green);
    color: var(--color-primary-bg);
    font-weight: bold;
    transition: .35s ease-in-out;
    margin-block: 10px;
}

.btn.add-to-cart:hover {
    color: #fff;
    background-color: var(--color-pink);
}

.product-card_image {
    position: relative;
    border-bottom: 1px solid var(--color-pink);
    background: var(--color-primary-bg)
}

.product-card.on-sale {
    border: 1px solid var(--color-green);
    box-shadow: 0px 0px 30px var(--color-green);
}

.price-wrapper {
    display: flex;
}

.product-price {
    font-size: 20px;
    font-weight: bold;
    color: var(--color-green);
    margin-top: 10px;
}

.sale-price {
    text-shadow: 0px 0px 10px var(--color-green);
}


.original-price {
    text-decoration: line-through;
    color: var(--color-pink);
    font-size: 14px;
}

.filter-menu {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
    padding: 10px;
    background-color: var(--color-accent-glass);
}

.filter-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.filter-group h4 {
    margin: 0;
    font-size: 16px;
    color: var(--color-green);
}

.filter-group input[type="range"] {
    width: 100%;
}

.filter-group label {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    color: var(--color-primary);
}

.filter-group select {
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    width: 100%;
    color: #fff;
}

.filter-group select>option {
    background: var(--color-primary-bg)
}

.product-page {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 50px;
}

.skins-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
}

.skin-item img {
    width: 100%;
    height: 100%;
}

.pagination {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    color: var(--color-green);
}

.pagination span {
    width: 100%;
    text-align: center;
    font-size: 16px;
}

.skin-item {
    position: relative;
    cursor: pointer;
}

.skin-name {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: 100%;
    font-weight: bold;
    color: var(--color-green);
    z-index: 10;
}

.skin-item.selected:before {
    background: linear-gradient(45deg, var(--color-green), transparent);
}

.skin-item.selected .skin-name {
    color: var(--color-neutral-dark)
}

.skin-item::before {
    content: '';
    background: linear-gradient(45deg, #1e0633, transparent);
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #ffffff38;
}

@media (max-width: 1280px) {
    .product-card {
        flex-basis: calc(50% - 20px);
        width: 50%;
    }

    .product-page {
        display: block;
    }

    .skins-grid {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 768px) {
    .product-card {
        flex-basis: 100%;
        width: 100%;
    }

    .skins-grid {
        grid-template-columns: 1fr 1fr;
    }
}
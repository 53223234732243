.card-element {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid var(--color-neutral-dark);
    border-radius: 5px;
    background: white;
}

.payment-methods label {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1rem;
}

.card-element,
.payment-element {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid var(--color-neutral-dark);
    border-radius: 5px;
    background: white;
}

.checkout-page h1 {
    color: var(--color-green);
    text-align: center;
    text-shadow: 0px 0px 10px var(--color-green);
}

.checkout-box {
    background: var(--color-accent-glass);
    padding: 20px;
}

.checkout-box .add-to-cart {
    margin-top: 40px;
}

.card-element {
    background: rgba(255, 255, 255, 0.1);
    /* Transparante achtergrond */
    border: 1px solid rgba(255, 255, 255, 0.2);
    /* Randkleur */
    border-radius: 8px;
    color: #fff;
}
.text-white {
    color: #fff;
}

.text-pink {
    color: var(--color-pink);
}

.text-shadow-pink {
    text-shadow: 0px 0px 10px var(--color-pink);
}

.text-center {
    text-align: center;
}

.text-field {
    padding: 20px !important;
}

.home {
    color: #fff;
}
/* Skeleton Loader */
.skeleton-card {
    background-color: var(--color-accent-glass);
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    animation: pulse 1.5s infinite ease-in-out;
    height: 505px;
}

.skeleton-image {
    width: 100%;
    height: 250px;
    background: rgba(255, 255, 255, 0.2);
}

.skeleton-text {
    background-color: var(--color-glass);
    border-radius: 4px;
    margin: 10px 0;
}

.skeleton-title {
    width: 80%;
    height: 20px;
}

.skeleton-badges {
    width: 60%;
    height: 15px;
}

.skeleton-price {
    width: 50%;
    height: 20px;
}

.skeleton-button {
    width: 100%;
    height: 40px;
    border-radius: 5px;
}

/* Pulse Animation */
@keyframes pulse {
    0% {
        background-color: var(--color-accent-glass);
    }

    50% {
        background-color: var(--color-glass);
    }

    100% {
        background-color: var(--color-accent-glass);
    }
}
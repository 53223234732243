.account-content {
    text-align: center;
}

h2,
h3 {
    color: var(--color-green);
    text-shadow: 0px 0px 10px var(--color-green);
    margin-bottom: 20px;
}

.payments-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    text-align: left;
    background-color: var(--color-accent-glass);
    color: #fff;
}

.payments-table th {
    background-color: var(--color-accent-glass);
    border: 1px solid var(--color-green);
    padding: 10px;
}

.payments-table td {
    padding: 10px;
    border: 1px solid var(--color-green);
}

.payments-table tr:nth-child(odd) {
    background: var(--color-glass);
}

.loading-container {
    text-align: center;
    margin-top: 50px;
}

.password-reveal {
    position: relative;
}

.password-reveal .tooltip {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
}

.password-reveal span:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

.password-reveal svg {
    font-size: 18px;
    color: #fff;
}
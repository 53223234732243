.cart-items {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.cart h1 {
    color: var(--color-green);
    text-align: center;
    font-size: 2rem;
    text-shadow: 0px 0px 10px var(--color-green);
}

.cart h4 {
    font-size: 1.125rem;
}

.cart-item {
    display: flex;
    gap: 20px;
    align-items: center;
    background-color: var(--color-accent-glass);
    padding: 10px;
    border-radius: 8px;
}

.cart-item:nth-child(even) {
    background-color: var(--color-glass);
}

.cart-item-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
}

.cart-item-details {
    flex: 1;
}

.btn-remove {
    background-color: var(--color-pink);
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn-remove:hover {
    background-color: var(--color-pink);
}

.cart-total {
    border-top: 1px solid var(--color-accent-bg);
    margin-top: 20px;
    padding-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    align-items: center;
}

.btn-checkout {
    background-color: var(--color-secondary);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn-checkout:hover {
    background-color: var(--color-btn-bg-hover);
}

.cart-wrapper {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 20px;
}

@media (max-width: 768px) {
    .cart-wrapper {
        display: block;
    }

    .cart-wrapper>* {
        margin: 10px;
    }
}

.discount-box {
    background-color: var(--color-accent-glass);
    padding: 20px;
    border-radius: 8px;
}

.discount-box h2 {
    font-size: 1.5em;
}

.total {
    color: var(--color-green)
}

.total-price {
    font-size: 18px;
    color: var(--color-green);
    font-weight: bold;
}
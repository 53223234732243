.box-wrapper {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    /* 3 kolommen standaard */
}

.two-cols {
    display: flex;
    align-items: center;
    gap: 50px;
    text-align: center;
}

.left,
.right {
    width: 50%;
}

.stars {
    margin-block: 10px;
}

.usp-box:before {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #00f5d440, transparent);
    position: absolute;
    z-index: -1;
}

@media (max-width: 1024px) {
    .box-wrapper {
        grid-template-columns: repeat(2, 1fr);
        /* 2 kolommen op tablets */
    }
}

@media (max-width: 768px) {
    .box-wrapper {
        grid-template-columns: 1fr;
        /* 1 kolom op mobiele apparaten */
    }
}
.navbar a {
    color: #fff;
}

.navbar .logo img {
    aspect-ratio: 1/1;
    width: 180px;
}

.nav-icon {
    position: relative;
}

.navbar .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.navbar {
    background: var(--color-accent-bg);

}

.navbar>.container {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    position: relative;
    padding-block: 20px;
}

.navbar-left .nav-links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 25px;
    list-style-type: none;
    padding: 0;
    justify-content: flex-start;
    height: 100%;
}

.navbar-right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 25px;
    list-style-type: none;
    padding: 0;
    justify-content: flex-end;
}

.icon-bubble {
    position: absolute;
    background: var(--color-pink);
    border-radius: 100%;
    width: 16px;
    height: 16px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    top: -10px;
    right: -10px;
}

.top-bar {
    position: relative;
    overflow: hidden;
    padding: 10px 0;
    background: var(--color-glass);
    white-space: nowrap;

}

.marquee {
    display: flex;
    width: 200%;
    /* Zorg dat de inhoud buiten beeld past */
    animation: scroll 10s linear infinite;
}

.marquee-content {
    display: flex;
    justify-content: space-around;
    /* Zorg dat de teksten mooi verdeeld zijn */
    flex-shrink: 0;
    width: 100%;
    /* Eén helft van de container */
}

.marquee-content p {
    margin: 0;
    padding: 0 20px;
    white-space: nowrap;
}

@keyframes scroll {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-50%);
        /* Scroll door de helft van de totale breedte */
    }
}